import {
    GET_PRODUCT_TYPES_ON_FILTERS_REQUEST,
    GET_PRODUCT_TYPES_ON_FILTERS_SUCCESS,
    GET_PRODUCT_TYPES_ON_FILTERS_FAILURE,
    CLEAR_PRODUCT_TYPES_ON_FILTERS,
} from '../constants/actions/types';

const initialState = {
    data: [],
    fetching: false
};

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_PRODUCT_TYPES_ON_FILTERS_REQUEST: {
            return {
                ...state,
                data: [],
                fetching: true
            };
        }
        case GET_PRODUCT_TYPES_ON_FILTERS_SUCCESS: {
            return {
                ...state,
                data: action.payload.productTypes,
                fetching: false
            };
        }
        case GET_PRODUCT_TYPES_ON_FILTERS_FAILURE: {
            return {
                ...state,
                fetching: false
            };
        }
        case CLEAR_PRODUCT_TYPES_ON_FILTERS: {
            return {
                ...state,
                data: [],
                fetching: false
            }
        }
        default:
            return state;
    }
};