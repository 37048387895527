import React, { memo, useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { docTitle } from '../../constants/utils/conf';

const PublicRoute = ({ component: Component, label, ...rest }) => {
    const { t } = useTranslation("menu");
    const authStates = useSelector((state) => state.auth);

    useEffect(() => {
        document.title = `${t(label)}\u00A0\u00A0\u00A0|\u00A0\u00A0\u00A0${docTitle}` || "";
      }, [label, t]);

    return (
        <Route
            {...rest}
            render={(props) =>
                authStates.isAuthenticated &&
                !authStates.loading &&
                (label === "signin" || label === "signup") ? (
                    <Redirect to="/home" />
                ) : (
                    <Component {...props} />
                )
            }
        />
    );
};

export default memo(PublicRoute);
