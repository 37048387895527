import axios from "axios";

const setAuthToken = (access_token) => {
    if (access_token) {
        axios.defaults.headers.common["x-access-token"] = access_token;
    } else {
        delete axios.defaults.headers.common["x-access-token"];
    }
};

export default setAuthToken;
