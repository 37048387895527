import { cdnFiles } from "../constants/utils/conf"

export const buildCompany = (company)=>{
    const access_token = localStorage.getItem("access_token");
    const companybuild = {
        ...company, 
        picture: company.picture ? `${cdnFiles(company.picture)}?token=${access_token}` : null
    };

    return companybuild
}

export const validateTimeFormat = (value) => {
    var isValid = /^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$/.test(value);

    return isValid;
}