import {
    SET_FILTERS_PRODUCTSTYPES
} from "../constants/actions/types";

export const initialState = {
    text: ""
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_FILTERS_PRODUCTSTYPES: {
            return {
                ...state,
                text: action.payload.text
            };
        }
        default:
            return state;
    }
};
