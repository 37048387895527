import webConfigurations, { companies } from '../api/index';

const towSVG = `/assets/icons/veggie.svg`;

const pathname = window.location.pathname.split('/').filter(i => i);

const availableCompanies = Object.keys(companies);

export const company = availableCompanies.includes(pathname[0]) ? pathname[0] : 'lamundial';

const conf = webConfigurations(company);

export const defaultPage = 0;

export const defaultLimit = 20;

export const apiKey = "X1p31DRc47tgOOt0pwBLvjpyCwStGlW8LFZDmnCm";

export const googleMapApiKey = "AIzaSyA-gGthtz_t0kO9-4rLITxRAxIJnwfHpvc";

export const { api, cdnFiles, companyCode, docTitle } = conf;

export const headers = { "Content-Type": "application/json" };

export const defaultEnabledOptionsForm = [
    {
        value: true,
        label: 'users:enabled',
        checked: true
    },
    {
        value: false,
        label: 'users:disabled',
        checked: false
    }
];

export const userRoles = [
    {
        value: "administrator",
        label: 'users:administrator',
    },
    {
        value: "operator",
        label: 'users:operator',
    }
];

export const defaultOptionsForm = {
    value: null,
    label: 'common:none'
};

export const saleStatus = [
    {
        label: "cancelled",
        value: "cancelled",
        color: "#777"
    },
    {
        label: "created",
        value: "created",
        color: "#c7150f"
    },
    {
        label: "preparing",
        value: "preparing",
        color: "#d19551"
    },
    {
        label: "rejected",
        value: "rejected",
        color: "#720072"
    },
    {
        label: "delivering",
        value: "delivering",
        color: "#ecd345"
    },
    {
        label: "completed",
        value: "completed",
        color: "#449c4c"
    }
]

export const initialSinisters = [
    {
        key: "sales_year",
        title: "dashboard:sales_year",
        total: 0,
        icon: towSVG,
        background: "linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%)",
        svgfill: "#8597b5",
        fontColor: "#8597b5",
        markerIcon: "./assets/markers/red_marker.png"
    },
    {
        key: "sales_month",
        title: "dashboard:sales_month",
        total: 0,
        icon: towSVG,
        background: "linear-gradient(120deg, #84fab0 0%, #8fd3f4 100%)",
        svgfill: "#388a82",
        fontColor: "#388a82",
        markerIcon: "./assets/markers/red_marker.png"
    },
    {
        key: "sales_week",
        title: "dashboard:sales_week",
        total: 0,
        icon: towSVG,
        background: "linear-gradient(45deg, #ff9a9e 0%, #fad0c4 99%, #fad0c4 100%)",
        svgfill: "#da6666",
        fontColor: "#da6666",
        markerIcon: "./assets/markers/red_marker.png"
    },
    {
        key: "sales_day",
        title: "dashboard:sales_day",
        total: 0,
        icon: towSVG,
        background: "linear-gradient(120deg, #d4fc79 0%, #96e6a1 100%)",
        svgfill: "#4c9255",
        fontColor: "#4c9255"
    }
];

const companySettings = {
    agrosano: {
        name: `Agrosano`,
        email: `-`,
        address: `-`,
        phone: `-`,
        year: `1999`,
    },
    lamundial: {
        name: `La Mundial`,
        email: `info@tiendalamundial.com.ar`,
        address: `Ruta E55 Km 7 1/2, La Calera, Cordoba`,
        phone: `03543 466553`,
        year: `1999`,
    },
    tumercadocordoba: {
        name: `Tu Mercado Córdoba`,
        email: `tumercadocordoba@gmail.com`,
        address: `Córdoba, Argentina`,
        phone: `0351 1555555`,
        year: `1999`,
    },
};

export const getCompanyData = () => companySettings[company]