import { SET_FILTERS_USERS } from '../constants/actions/types';

const initialState = {
    text: ''
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_FILTERS_USERS: {
            return {
                ...state,
                text: action.payload.text
            };
        }
        default:
            return state;
    }
};
