import {
    GET_PURCHASES_REQUEST,
    GET_PURCHASES_SUCCESS,
    GET_PURCHASES_FAILURE,
    SET_PURCHASES,
    SET_PURCHASE,
    SUBMIT_PURCHASE_REQUEST,
    SUBMIT_PURCHASE_SUCCESS,
    SUBMIT_PURCHASE_FAILURE,
    EXPORT_FILE_REQUEST,
    EXPORT_FILE_SUCCESS,
    EXPORT_FILE_FAILURE
} from '../constants/actions/types';

const initialState = {
    request: {
        fetching: true,
        success: false
    },
    submit: {
        fetching: false,
        success: false,
        fetchingExport: false
    },
    purchases: [],
    purchase: {},
    total: 0
};

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_PURCHASES_REQUEST: {
            return {
                ...state,
                request: {
                    ...state.request,
                    fetching: true
                }
            };
        }
        case GET_PURCHASES_SUCCESS: {
            return {
                ...state,
                request: {
                    ...state.request,
                    fetching: false
                },
                purchases: [
                    ...state.purchases,
                    ...action.payload.purchases
                ],
                total: (!action.payload.total || action.payload.total === 0)
                    ? state.total
                    : action.payload.total
            };
        }
        case GET_PURCHASES_FAILURE: {
            return {
                ...state,
                request: {
                    ...state.request,
                    fetching: false
                },
            };
        }
        case SET_PURCHASES: {
            return {
                ...state,
                purchases: action.payload.purchases,
                total: action.payload.total
            };
        }
        case SET_PURCHASE: {
            return {
                ...state,
                purchase: action.payload.purchase
            };
        }

        case SUBMIT_PURCHASE_REQUEST: {
            return {
                ...state,
                submit: {
                    ...state.submit,
                    fetching: true
                }
            };
        }
        case SUBMIT_PURCHASE_SUCCESS: {
            return {
                ...state,
                submit: {
                    ...state.submit,
                    fetching: false,
                    success: true
                }
            };
        }
        case SUBMIT_PURCHASE_FAILURE: {
            return {
                ...state,
                submit: {
                    ...state.submit,
                    fetching: false,
                    success: false
                }
            };
        }
        case EXPORT_FILE_FAILURE: {
            return {
                ...state,
                request:{
                    ...state.request,
                    fetchingExport:false
                }
            }
        } 
        case EXPORT_FILE_SUCCESS: {
            return {
                ...state,
                request:{
                    ...state.request,
                    fetchingExport:false
                }
            }
        }
        case EXPORT_FILE_REQUEST: {
            return {
                ...state,
                request:{
                    ...state.request,
                    fetchingExport:true
                }
            }
        }
        default:
            return state;
    }
};
