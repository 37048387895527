import {
    GET_SALES_REQUEST,
    GET_SALES_SUCCESS,
    GET_SALES_FAILURE,
    GET_SALE_SUCCESS,
    SET_SALES,
    SET_SALE,
    PUT_SALE_REQUEST,
    PUT_SALE_SUCCESS,
    PUT_SALE_FAILURE,
    EXPORT_FILE_REQUEST,
    EXPORT_FILE_SUCCESS,
    EXPORT_FILE_FAILURE,
    POST_SALE_REQUEST,
    POST_SALE_SUCCESS,
    POST_SALE_FAILURE,
    RESET_SALE_REQUEST,
    POST_MERCADOPAGO_REQUEST,
    POST_MERCADOPAGO_SUCCESS,
    POST_MERCADOPAGO_FAILURE,
    RESET_MERCADOPAGO_REQUEST,
    RESET_MERCADOPAGO_PREFERENCEID
} from '../constants/actions/types';

const initialState = {
    request: {
        fetching: false,
        success: false
    },
    requestMercadoPago: {
        fetching: false,
        success: false
    },
    sales: [],
    sale: {},
    total: 0,
    preferenceId: ""
};

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_SALES_REQUEST: {
            return {
                ...state,
                request: {
                    fetching: true,
                    success:false
                }
            };
        }
        case GET_SALES_SUCCESS: {
            return {
                ...state,
                request: {
                    ...state.request,
                    fetching: false
                },
                sales: [
                    ...state.sales,
                    ...action.payload.sales
                ],
                total: (!action.payload.total || action.payload.total === 0)
                    ? state.total
                    : action.payload.total
            };
        }
        case GET_SALE_SUCCESS: {
            return {
                ...state,
                request: {
                    ...state.request,
                    fetching: false
                },
                sale: action.payload.sale
            };
        }
        case GET_SALES_FAILURE: {
            return {
                ...state,
                request: {
                    ...state.request,
                    fetching: false
                },
            };
        }
        case SET_SALES: {
            return {
                ...state,
                sales: action.payload.sales,
                total: action.payload.total
            };
        }
        case SET_SALE: {
            return {
                ...state,
                sale: action.payload.sale
            };
        }
        case PUT_SALE_REQUEST:{
            return {...state,request:{...state.request,fetching:true},}
        }
        case PUT_SALE_FAILURE:{
            return {...state,request:{...state.request,fetching:false},}
        }
        case PUT_SALE_SUCCESS: {
            return {
                ...state,
                request:{...state.request,fetching:false, success: true},
                sale: action.payload
            }
        }
        case EXPORT_FILE_FAILURE: {
            return {
                ...state,
                request:{
                    ...state.request,
                    fetchingExport:false
                }
            }
        } 
        case EXPORT_FILE_SUCCESS: {
            return {
                ...state,
                request:{
                    ...state.request,
                    fetchingExport:false
                }
            }
        }
        case EXPORT_FILE_REQUEST: {
            return {
                ...state,
                request:{
                    ...state.request,
                    fetchingExport:true
                }
            }
        }
        case POST_SALE_REQUEST:{
            return {
                ...state,
                request:{...state.request,fetching:true, success: false}
            }
        }
        case POST_SALE_SUCCESS: {
            return {
                ...state,
                request:{...state.request,fetching:false, success: true},
                sale: action.payload
            }
        }
        case POST_SALE_FAILURE:{
            return {...state,request:{...state.request,fetching:false},}
        }
        case RESET_SALE_REQUEST: {
            return {
                ...state,
                request:{...state.request,fetching: false, success: false}
            }
        }
        case POST_MERCADOPAGO_REQUEST:{
            return {
                ...state,
                requestMercadoPago:{...state.requestMercadoPago,fetching:true, success: false}
            }
        }
        case POST_MERCADOPAGO_SUCCESS: {
            return {
                ...state,
                requestMercadoPago:{
                    ...state.requestMercadoPago,
                    fetching:false, 
                    success: true
                },
                preferenceId: action.payload
            }
        }
        case POST_MERCADOPAGO_FAILURE:{
            return {
                ...state,
                requestMercadoPago:{...state.requestMercadoPago,fetching:false}
            }
        }
        case RESET_MERCADOPAGO_REQUEST: {
            return {
                ...state,
                requestMercadoPago:{...state.request,fetching: false, success: false}
            }
        }
        case RESET_MERCADOPAGO_PREFERENCEID: {
            return {
                ...state,
                preferenceId: ""
            }
        }
        default:
            return state;
    }
};
