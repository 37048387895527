import React, { Suspense, useEffect, lazy } from "react";
import { HashRouter } from "react-router-dom";
import Loading from "../../components/shared/Loading/Loading";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "../../store";
import { refreshToken } from "../../actions/auth";
import ChangeTheme from "../../components/ChangeTheme/ChangeTheme";
import CustomRoutes from './CustomRoutes';
import Control from "./Control";

const Notification = lazy(() => import("../../components/shared/Notification/Notification"));
const Body = lazy(() => import("../../components/shared/Body/Body"));

const Root = () => {
    useEffect(() => {
        store.dispatch(refreshToken());
    }, []);

    return (
        <Provider store={store}>
            <PersistGate persistor={persistor}>
                <HashRouter basename='/'>
                    <Control>
                        <ChangeTheme />
                        <Suspense fallback={<Loading theme />}>
                            <Body>
                                <CustomRoutes/>
                                <Notification />
                            </Body>
                        </Suspense>
                    </Control>
                </HashRouter>
            </PersistGate>
        </Provider>
    );
};
export default Root;
