
import { getFinalPrice } from './products';

export const increaseProduct = (purchase, product) => {
    purchase.find((purch) => purch.product._id === product._id).quantity += 1;
    return purchase;
};

export const decreaseProduct = (purchase, product) => {
    purchase.find((purch) => purch.product._id === product._id).quantity -= 1;
    if (purchase.find((purch) => purch.product._id === product._id).quantity < 1) {
        return purchase.filter((purch) => purch.product._id !== product._id);
    }
    return purchase;
};

export const isAdded = (cartProducts, product) => {
    return cartProducts.find((prod) => prod.product._id === product._id);
};

export const totalPrice = (cartProducts) => {
    let total = 0;
    cartProducts.forEach((pruch) => {
        total += pruch.quantity * getFinalPrice(pruch.product, true);
    });
    return total.toFixed(2);
};

export const buildSalePost = (sale, user) => {
    let _sale = Object.assign({}, sale);

    _sale.date = new Date().toISOString();
    _sale.clientId = user._id;
    _sale.client = {
        address: user.address,
        displayName: `${user.name} ${user.lastName}`,
    };

    _sale.saleItems = [];
    if (sale.purchase) {
        sale.purchase.forEach((item) => {
            _sale.saleItems.push({
                product: item.product._id,
                quantity: item.quantity
            });
        });
    }
    delete _sale.purchase;

    return _sale;
};

export const buildMercadoPagoPost = (sale, user) => {
    let _sale = Object.assign({}, sale);

    _sale.clientId = user._id;
    _sale.saleItems = [];
    if (sale.purchase) {
        sale.purchase.forEach((item) => {
            _sale.saleItems.push({
                product: item.product._id,
                quantity: item.quantity
            });
        });
    }
    delete _sale.purchase;
    delete _sale.paymentMethod;
    delete _sale.deliveryMethod;

    return _sale;
};
