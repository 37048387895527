import { cdnFiles } from "../constants/utils/conf";

export const buildProductsTypesGet = (productsTypes) => productsTypes.map((productsType) => buildProductsTypeGet(productsType));

const buildProductsTypeGet = (productType) => {
    return {
        ...productType
    };
};

export const buildProductPost = (data) => {
    let _data = Object.assign({}, data);
    if (data.name) _data.name = data.name;
    if (data.description) _data.description = data.description;
    if (data.enabledPicture) _data.picture = data.enabledPicture
    return _data;
};

export const buildProductForm = (data) => {
    let _data = Object.assign({}, data);
    if (data.name) _data.name = data.name;
    if (data.description) _data.description = data.description;
    if (data.enabledPicture) _data.picture = cdnFiles(data.enabledPicture)
    return _data
};