import {
    GET_PRODUCTS_REQUEST,
    GET_PRODUCTS_SUCCESS,
    GET_PRODUCTS_FAILURE,
    GET_PRODUCT_REQUEST,
    GET_PRODUCT_SUCCESS,
    GET_PRODUCT_FAILURE,
    SET_PRODUCTS,
    SET_PRODUCT,
    SUBMIT_PRODUCT_REQUEST,
    SUBMIT_PRODUCT_SUCCESS,
    SUBMIT_PRODUCT_FAILURE,
    DELETE_PRODUCT_REQUEST,
    DELETE_PRODUCT_SUCCESS,
    DELETE_PRODUCT_FAILURE,
    UPLOAD_PRODUCT_FILE_EXAMPLE_FAILURE,
    UPLOAD_PRODUCT_FILE_EXAMPLE_SUCCESS,
    UPLOAD_PRODUCT_FILE_EXAMPLE_REQUEST,
    EXPORT_FILE_REQUEST,
    EXPORT_FILE_SUCCESS,
    EXPORT_FILE_FAILURE,
} from "../constants/actions/types";

import { buildProductsGet, buildProductGet } from "../helpers/products";

const initialState = {
    request: {
        fetching: false,
        success: false,
    },
    submit: {
        fetching: false,
        success: false,
    },
    deleted: {
        fetching: false,
        success: false,
    },
    uploaded: {
        fetching: false,
        success: false,
    },
    products: [],
    product: {},
    total: 0,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_PRODUCTS_REQUEST: {
            return {
                ...state,
                request: {
                    ...state.request,
                    fetching: true,
                },
                submit: {
                    ...state.submit,
                    success: false,
                },
            };
        }
        case GET_PRODUCTS_SUCCESS: {
            return {
                ...state,
                request: {
                    ...state.request,
                    fetching: false,
                },
                uploaded: {
                    fetching: false,
                    success: false,
                },
                products: [...state.products, ...buildProductsGet(action.payload.products)],
                total:
                    !action.payload.total || action.payload.total === 0
                        ? state.total
                        : action.payload.total,
            };
        }
        case GET_PRODUCTS_FAILURE: {
            return {
                ...state,
                request: {
                    ...state.request,
                    fetching: false,
                },
            };
        }
        case GET_PRODUCT_REQUEST: {
            return {
                ...state,
                request: {
                    ...state.request,
                    fetching: true,
                },
                submit: {
                    ...state.submit,
                    success: false,
                },
            };
        }
        case GET_PRODUCT_SUCCESS: {
            return {
                ...state,
                request: {
                    ...state.request,
                    fetching: false,
                },
                uploaded: {
                    fetching: false,
                    success: false,
                },
                product: buildProductGet(action.payload.product),
            };
        }
        case GET_PRODUCT_FAILURE: {
            return {
                ...state,
                request: {
                    ...state.request,
                    fetching: false,
                },
            };
        }
        case SET_PRODUCTS: {
            return {
                ...state,
                products: action.payload.products,
                total: action.payload.total,
            };
        }
        case SET_PRODUCT: {
            return {
                ...state,
                product: action.payload.product,
            };
        }

        case SUBMIT_PRODUCT_REQUEST: {
            return {
                ...state,
                submit: {
                    ...state.submit,
                    fetching: true,
                },
            };
        }
        case SUBMIT_PRODUCT_SUCCESS: {
            return {
                ...state,
                submit: {
                    ...state.submit,
                    fetching: false,
                    success: true,
                },
            };
        }
        case SUBMIT_PRODUCT_FAILURE: {
            return {
                ...state,
                submit: {
                    ...state.submit,
                    fetching: false,
                    success: false,
                },
            };
        }
        case DELETE_PRODUCT_REQUEST: {
            return {
                ...state,
                deleted: {
                    ...state.deleted,
                    fetching: true,
                    success: false,
                },
            };
        }
        case DELETE_PRODUCT_SUCCESS: {
            return {
                ...state,
                deleted: {
                    ...state.deleted,
                    fetching: false,
                    success: true,
                },
            };
        }
        case DELETE_PRODUCT_FAILURE: {
            return {
                ...state,
                deleted: {
                    ...state.deleted,
                    fetching: false,
                    success: false,
                },
            };
        }
        case UPLOAD_PRODUCT_FILE_EXAMPLE_REQUEST: {
            return {
                ...state,
                uploaded: {
                    fetching: true,
                    success: false,
                },
            };
        }
        case UPLOAD_PRODUCT_FILE_EXAMPLE_FAILURE: {
            return {
                ...state,
                uploaded: {
                    fetching: false,
                    success: false,
                },
            };
        }
        case UPLOAD_PRODUCT_FILE_EXAMPLE_SUCCESS: {
            return {
                ...state,
                uploaded: {
                    fetching: false,
                    success: true,
                },
            };
        }
        case EXPORT_FILE_FAILURE: {
            return {
                ...state,
                request: {
                    ...state.request,
                    fetchingExport: false,
                },
            };
        }
        case EXPORT_FILE_SUCCESS: {
            return {
                ...state,
                request: {
                    ...state.request,
                    fetchingExport: false,
                },
            };
        }
        case EXPORT_FILE_REQUEST: {
            return {
                ...state,
                request: {
                    ...state.request,
                    fetchingExport: true,
                },
            };
        }
        default:
            return state;
    }
};
