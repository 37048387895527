import i18n from "i18next";
import languageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from "react-i18next";
import xhr from 'i18next-xhr-backend';
import chainedBackend from 'i18next-chained-backend';
import localStorageBackend from 'i18next-localstorage-backend';

i18n
    .use(languageDetector)
    .use(chainedBackend)
    .use(initReactI18next)
    .init({
        detection: {
            order: ['navigator'],
        },
        fallbackLng: 'en',
        load: 'languageOnly',
        backend: {
            backends: [
                localStorageBackend,
                xhr
            ],
            backendOptions: [{
                prefix: 'i18nextLng_',
                expirationTime: 0
            }, {
                loadPath: '/locales/{{lng}}/{{ns}}.json'
            }]
        },
        keySeparator: false,
        interpolation: {
            escapeValue: false
        },
        returnNull: false,
        returnEmptyString: false,
        react: {
            wait: true
        }
    });

export default i18n;
