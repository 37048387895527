import {
    GET_PROVIDERS_REQUEST,
    GET_PROVIDERS_SUCCESS,
    GET_PROVIDERS_FAILURE,
    SET_PROVIDERS,
    SET_PROVIDER,
    SUBMIT_PROVIDER_REQUEST,
    SUBMIT_PROVIDER_SUCCESS,
    SUBMIT_PROVIDER_FAILURE
} from '../constants/actions/types';

const initialState = {
    request: {
        fetching: true,
        success: false
    },
    submit: {
        fetching: false,
        success: false
    },
    providers: [],
    provider: {},
    total: 0
};

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_PROVIDERS_REQUEST: {
            return {
                ...state,
                request: {
                    ...state.request,
                    fetching: true
                },
                submit: {
                    ...state.submit,
                    success: false
                }
            };
        }
        case GET_PROVIDERS_SUCCESS: {
            return {
                ...state,
                request: {
                    ...state.request,
                    fetching: false
                },
                providers: [
                    ...state.providers,
                    ...action.payload.providers
                ],
                total: (!action.payload.total || action.payload.total === 0)
                    ? state.total
                    : action.payload.total
            };
        }
        case GET_PROVIDERS_FAILURE: {
            return {
                ...state,
                request: {
                    ...state.request,
                    fetching: false
                },
            };
        }
        case SET_PROVIDERS: {
            return {
                ...state,
                providers: action.payload.providers,
                total: action.payload.total
            };
        }
        case SET_PROVIDER: {
            return {
                ...state,
                provider: action.payload.provider
            };
        }

        case SUBMIT_PROVIDER_REQUEST: {
            return {
                ...state,
                submit: {
                    ...state.submit,
                    fetching: true
                }
            };
        }
        case SUBMIT_PROVIDER_SUCCESS: {
            return {
                ...state,
                submit: {
                    ...state.submit,
                    fetching: false,
                    success: true
                }
            };
        }
        case SUBMIT_PROVIDER_FAILURE: {
            return {
                ...state,
                submit: {
                    ...state.submit,
                    fetching: false,
                    success: false
                }
            };
        }
        default:
            return state;
    }
};
