import { SET_THEME, SET_VERSION } from '../constants/actions/types';
import { SAVE_THEME_V1 } from '../constants/localStorage/storage_keys';


export const setThemeWeb = (theme, site) => (dispatch, getState) => _setThemeWeb(theme, site, dispatch, getState);
const _setThemeWeb = (theme, site, dispatch, getState) => {
    try {
        dispatch({ type: SET_THEME, payload: { theme } });
        !site && localStorage.setItem(SAVE_THEME_V1, theme);
    } catch (error) {
        console.log('SET_THEME', error);
    }
};

export const getThemeFromStorage = () => (dispatch, getState) => _getThemeFromStorage(dispatch, getState);
const _getThemeFromStorage = async (dispatch, getState) => {

    try {
        let theme = localStorage.getItem(SAVE_THEME_V1);

        if (theme) {
            dispatch({ type: SET_THEME, payload: { theme } });
        }

    } catch (error) {
        console.log('REQUEST REGISTER ERROR', error);
        throw new Error('theme not found ')
    }
};

export const setVersion = (version) => ({
    type: SET_VERSION,
    payload: {
        version
    }
});