import { SET_FILTERS_PROVIDERS } from "../constants/actions/types";

export const initialState = {
    text: "",
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_FILTERS_PROVIDERS: {
            return {
                ...state,
                text: action.payload.text,
            };
        }
        default:
            return state;
    }
};
