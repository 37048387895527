import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import auth from "./auth";
import alert from "./alert";
import config from "./config";
import users from "./users";
import dashboard from "./dashboard";
import products from "./products";
import productsFilters from "./productsFilters";
import autocompleteProviders from "./autocompleteProviders";
import autocompleteProductTypes from "./autocompleteProductTypes";
import autocompleteProducts from "./autocompleteProducts";
import usersFilter from "./usersFilter";
import productTypes from './productTypes';
import productTypesFilter from './productTypesFilter';
import providers from "./providers";
import providersFilters from "./providersFilters";
import sales from "./sales";
import salesFilters from "./salesFilters";
import purchases from "./purchases";
import purchasesFilters from "./purchasesFilters";
import company from './company';
import cart from "./cart";

const persistConfig = {
    key: "root",
    storage,
    blacklist: ["alert"],
    whitelist: ["auth","company","cart","sales","config"],
};

const rootReducer = combineReducers({
    alert,
    auth,
    config,
    users,
    dashboard,
    products,
    productsFilters,
    autocompleteProviders,
    autocompleteProductTypes,
    autocompleteProducts,
    usersFilter,
    productTypes,
    productTypesFilter,
    providers,
    providersFilters,
    sales,
    salesFilters,
    purchases,
    purchasesFilters,
    company,
    cart
});

export default persistReducer(persistConfig, rootReducer);
