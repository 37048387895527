import React, { memo, lazy, useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { docTitle } from '../../constants/utils/conf';

const Header = lazy(() => import("../../components/shared/Header/Header"));

const ProtectedRoute = ({ component: Component,permissions, label, subtitle, ...rest }) => {
    const { t } = useTranslation("menu");
    const authStates = useSelector((state) => state.auth);

    useEffect(() => {
        document.title = `${t(label)}\u00A0\u00A0\u00A0|\u00A0\u00A0\u00A0${docTitle}` || "";
      }, [label, t]);

    return (
        <Route
            {...rest}
            render={(props) =>
                !authStates.isAuthenticated && !authStates.loading ? (
                    <Redirect to="/home" />
                ) : ((permissions && 
                    <>
                        {permissions !== "siteAuth" && <Header title={label} subtitle={subtitle} />}
                        <Component {...props} />
                    </>) 
                    || <Redirect to="/home"/>
                )
            }
        />
    );
};

export default memo(ProtectedRoute);
