import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import "react-day-picker/lib/style.css";
import "./styles/themeColors.scss";

import Root from "./constants/routes/Root";
import "./i18next";

ReactDOM.render(<Root />, document.getElementById("root"));
