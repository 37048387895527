import { 
    ADD_TO_CART, 
    DELETE_FROM_CART, 
    INCREASE_CART_PRODUCT, 
    DECREASE_CART_PRODUCT, 
    EMPTY_CART,
    CHANGE_DELIVERY_TYPE,
    CHANGE_PAYMENT_METHOD
} from "../constants/actions/types";

export const addToCart = (product) => {
    return {
        type: ADD_TO_CART,
        payload: { product },
    };
};

export const deleteFromCart = (product) => {
    return {
        type: DELETE_FROM_CART,
        payload: { product },
    };
};

export const increaseCartProduct = (product) => {
    return {
        type: INCREASE_CART_PRODUCT,
        payload: { product },
    };
};

export const decreaseCartProduct = (product) => {
    return {
        type: DECREASE_CART_PRODUCT,
        payload: { product },
    };
};

export const emptyCart = () => {
    return {
        type: EMPTY_CART
    };
};

export const changeDeliveryType = (deliveryMethod) => {
    return {
        type: CHANGE_DELIVERY_TYPE,
        payload: { deliveryMethod }
    };
};

export const changePaymentMethod = (paymentMethod) => {
    return {
        type: CHANGE_PAYMENT_METHOD,
        payload: { paymentMethod }
    };
};