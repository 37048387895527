import React, { memo, lazy, Suspense, useState, useEffect } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import ProtectedRoute from "./ProtectedRoute";
import PublicRoute from "./PublicRoute";
import { adminRoutes, siteRoutes } from "./routes";
import Loading from "../../components/shared/Loading/Loading";
import { useSelector } from "react-redux";
import { componentsPermission } from "../utils/webPermissions";

const Layout = lazy(() => import("../../components/shared/Layout/Layout"));

const Routes = () => {
    const [role, setRole] = useState("user");
    const authStates = useSelector((state) => state.auth);

    useEffect(() => {
        if (authStates.user) {
            setRole(authStates.user.roles[0]);
        }
    }, [authStates]);

    return (
        <Switch>
            <Route exact path="/">
                <Redirect to="/home" />
            </Route>
            <Layout>
                <Suspense fallback={<Loading theme />}>
                    <Switch>
                        {siteRoutes.map(({ path, component, permissions, label }, id) => {
                            if (path) {
                                if(!permissions) {
                                    return <PublicRoute exact key={id} path={path} component={component} label={label} />
                                } else {
                                    return <ProtectedRoute exact key={id} path={path} component={component} permissions={permissions} label={label} />
                                }
                            }
                            return null;
                        })}
                        {adminRoutes.map(({ path, component, label, subtitle }, id) => {
                            if (path)
                                return (
                                    <ProtectedRoute
                                        exact
                                        key={id}
                                        path={path}
                                        component={component}
                                        label={label}
                                        subtitle={subtitle}
                                        permissions={componentsPermission[role][label]}
                                    />
                                );
                            return null;
                        })}
                        <Route>
                            <Redirect to="/home" />
                        </Route>
                    </Switch>
                </Suspense>
            </Layout>
        </Switch>
    );
};

export default memo(Routes);
