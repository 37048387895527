import axios from 'axios';

export const parseToDni = (identifier) => String(identifier).replace(/\B(?=(\d{3})+(?!\d))/g, ".");

export class Delay{
    time=1000;
    callback=()=>{};
    constructor({callback=this.callback,time=this.time}){

        this.callback = callback;
        this.time = time;

        return this.useDelay;
    }
    setTime(ms){
        this.time = ms;
    }
    setCallback(callback){
        this.callback = callback;
    }
    useDelay(){
        return new Promise( (resolved) => {
            setTimeout( ()=> resolved(this.callback), this.time);
        });
    }
}
export const getPropertieInObject = (object={},getValue)=>{
    let propertie = object;
    for (const value of getValue.split('.')){
        propertie = propertie[value];
    }
    return propertie;
}

export const parseToId = (identifier) => {
    if(identifier.length === 9){
        var parsedIdentifier = identifier.substring(0,identifier.length-1)+"-"+identifier.substring(identifier.length-1);
        return String(parsedIdentifier).replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    }
    else{
        return String(identifier).replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    }
};

export const buildArrayLength = length => {
    let l = [];
    for (let index = 0; index < length; index++) {
        l.push(index);
    }
    return l;
};

export const emailIsValid = email => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email);

export const scrolly = (action, event) => {
    let element = event.target;
    if (Math.floor(element.scrollHeight - element.scrollTop)<= element.clientHeight) {
        action();
    }
};

export const makeQuery = filters => {
    if (!filters) return '';
    return Object.keys(filters)
        .reduce(function (a, k) {
            if (filters[k] || filters[k]===0) {
                a.push(k + '=' + encodeURIComponent(filters[k].toString().trim()));
                return a;
            } else {
                a.push("");
                return a
            }
        }, [])
        .join('&');
};

export const watchEventStatus = async ({url='',expected=200, requestEvery=1000, endAfter=120000, searchIntoPropertie})=>{

    const delay = (ms) => {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    let stillRequesting = true;
    setTimeout( ()=>{
        stillRequesting = false;
    }, endAfter)
    let response;
    while(stillRequesting){
        response = await axios.get(url);
        const status = getPropertieInObject(response,searchIntoPropertie);
        if (status === expected) break
        await delay(requestEvery);      
    }

    return response;

}

export const fileDownload = async ({name,url})=>{
    try{
        const token = localStorage.getItem("access_token");
        const resp = await fetch(`${url}?token=${token}`)
        const file = await resp.blob()
        const a = document.createElement("a");
        a.setAttribute('download', name);
        a.href = window.URL.createObjectURL(file);
        document.body.appendChild(a);
        a.click();
        a.remove();
    }
    catch(err){
        throw new Error(err)
    }
}