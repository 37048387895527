import { cdnFiles } from "../constants/utils/conf";
import axios from "axios";
import { api, headers } from "../constants/utils/conf";

export const checkFileTypeAndUpload = async (picture) => {
    const cdnFile = cdnFiles("");

    let fileName = null;

    if (picture && typeof picture === "string") {
        fileName = String(picture).replace(cdnFile, "");
    } else if (picture !== null || typeof picture !== "undefined") {
        fileName = await uploadFileWorker(picture);
    }

    return fileName;
};

const uploadFileWorker = async (file) => {
    const formDatafile = new FormData();

    formDatafile.append("file", file);

    let allFilenames = {};

    try {
        const res = await axios.post(`${api}v1/media`, formDatafile, { headers });
        allFilenames ={
            filenames: res.data.filenames[0],
            thumbnailFilenames: res.data.thumbnailFilenames[0]
        }
    } catch (err) {
        console.log(err);
    }

    return allFilenames;
};
