import React, { memo, useCallback, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { useSelector, useDispatch} from 'react-redux';
import { setVersion } from '../../actions/config';

const Control = withRouter(({ children, history }) => {
    const dispatch = useDispatch();

    const { version } = useSelector((state) => state.config);

    const cleanStorage = useCallback(() => {
        localStorage.clear();
        sessionStorage.clear();
    }, []);

    const versionControl = useCallback(() => {
        if (version && process.env.REACT_APP_VERSION !== version) {
            cleanStorage();
            dispatch(setVersion(process.env.REACT_APP_VERSION));
            history.go(0);
        }

        if (!version) {
            dispatch(setVersion(process.env.REACT_APP_VERSION));
        }
    }, [dispatch, version, cleanStorage, history]);

    useEffect(() => { 
        versionControl(); 
    }, [versionControl]);

    return (
        <>
            {children}
        </>
    )
});

export default memo(Control);
