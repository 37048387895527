import {
    SET_FILTERS_PRODUCTS
} from "../constants/actions/types";
import moment from "moment";

export const initialState = {
    text: "",
    dateFrom: new Date(moment().set({ hours: 0, minutes: 0, seconds: 0 }).subtract(1, "month")),
    dateTo: new Date(moment().set({ hours: 23, minutes: 59, seconds: 59 })),
    provider: null,
    productType: null
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_FILTERS_PRODUCTS: {
            return {
                ...state,
                text: action.payload.text,
                dateFrom: action.payload.dateFrom,
                dateTo: action.payload.dateTo,
                provider: action.payload.provider,
                productType: action.payload.productType,
            };
        }
        default:
            return state;
    }
};
