import {
    GET_COMPANY_REQUEST,
    GET_COMPANY_FAILURE,
    GET_COMPANY_SUCCESS,
    PUT_COMPANY_FAILURE,
    PUT_COMPANY_REQUEST,
    PUT_COMPANY_SUCCESS,
    GET_DELIVERY_METHODS_REQUEST,
    GET_DELIVERY_METHODS_SUCCESS,
    GET_DELIVERY_METHODS_FAILURE,
    GET_PAYMENT_METHODS_REQUEST,
    GET_PAYMENT_METHODS_SUCCESS,
    GET_PAYMENT_METHODS_FAILURE 
} from "../constants/actions/types";
import {buildCompany} from '../helpers/company';

export const initialState = {
    company:{
        country:"",
        state:"",
        city:"",
        address:"",
        picture:"",
        paymentMethods:[],
        deliveryMethods:[],
        billingAddress:{street:"",streetNumber:"",city:"",state:"",country:"",zipCode:""},
        shippingAddress:{street:"",streetNumber:"",city:"",state:"",country:"",zipCode:""},
        legalId:"",
        mainPhone:"",
        otherPhones:[],
        email:""
    },
    error:false,
    submit:false,
    fetching:false,
    deliveryMethods: {
        fetching: false,
        data: []
    },
    paymentMethods: {
        fetching: false,
        data: []
    }
}

export default function (state=initialState, action){
    switch(action.type){
        case GET_COMPANY_SUCCESS: return {...state, company:buildCompany(action.payload), error:false, fetching:false};
        case GET_COMPANY_REQUEST: return {...state, submit:false,error:false, fetching:true};
        case GET_COMPANY_FAILURE: return {...state, error:true, fetching:false};
        case PUT_COMPANY_SUCCESS: return {...state, company:buildCompany(action.payload), submit:true, fetching:false};
        case PUT_COMPANY_REQUEST: return {...state, error:false, fetching:true}; 
        case PUT_COMPANY_FAILURE: return {...state, error:true, fetching:false};
        case GET_DELIVERY_METHODS_REQUEST: 
        return {...state, deliveryMethods: 
            { ...state.deliveryMethods, fetching: true }};
        case GET_DELIVERY_METHODS_SUCCESS: 
        return {...state, deliveryMethods: 
            { ...state.deliveryMethods, fetching: false, data: action.payload.deliveryMethods }};
        case GET_DELIVERY_METHODS_FAILURE: 
        return {...state, deliveryMethods: 
            { ...state.deliveryMethods, fetching: false }};
        case GET_PAYMENT_METHODS_REQUEST: 
        return {...state, paymentMethods: 
            { ...state.paymentMethods, fetching: true }};
        case GET_PAYMENT_METHODS_SUCCESS: 
        return {...state, paymentMethods: 
            { ...state.paymentMethods, fetching: false, data: action.payload.paymentMethods }};
        case GET_PAYMENT_METHODS_FAILURE: 
        return {...state, paymentMethods: 
            { ...state.paymentMethods, fetching: false }};
        default: return state;
    }
}