import {
    LOADING_AUTH,
    REFRESH_SUCCESS,
    REFRESH_FAIL,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    REGISTER_SUCCESS,
    REGISTER_VALIDATE,
    REGISTER_FAIL,
    LOGOUT,
    NO_REFRESH,
} from "../constants/actions/types";
import axios from "axios";
import { setAlert } from "./alert";
import setAuthToken from "../constants/utils/setAuthToken";
import { api, headers, companyCode, company, apiKey } from "../constants/utils/conf";
import isJwtValid from "is-jwt-valid";
import decode from "jwt-decode";
import i18n from "../i18next";
import { loginAccess } from "../constants/utils/webPermissions";
import { _getCompany } from "./company";
import { emptyCart } from "./cart";

const saveToken = (data) => {
    localStorage.setItem("access_token", data.access_token);
    localStorage.setItem("refresh_token", data.refresh_token);
    localStorage.setItem("company", company);
};

const removeToken = (dispatch) => {
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
    localStorage.removeItem("company");
    dispatch(emptyCart());
};

export const refreshToken = () => async (dispatch) => {
    const access_token = localStorage.getItem("access_token");
    const lastCompany = localStorage.getItem("company");

    if (access_token && lastCompany === company) {
        const now = new Date().getTime() / 1000;
        const exp = decode(access_token).exp;
        if (!isJwtValid(access_token) || exp < now) {
            const body = JSON.stringify({ token: localStorage.getItem("refresh_token") });
            dispatch({
                type: LOADING_AUTH,
            });

            try {
                const res = await axios.post(`${api}access/refresh`, body, { headers });
                setAuthToken(res.data.access_token);
                dispatch({
                    type: REFRESH_SUCCESS,
                });
                saveToken(res.data);
            } catch (err) {
                dispatch({
                    type: REFRESH_FAIL,
                });
                removeToken(dispatch);
            }
        } else {
            setAuthToken(localStorage.access_token);
            dispatch({
                type: NO_REFRESH,
            });
        }
    } else {
        dispatch(logout());
    }
};

export const login = (username, password) => async (dispatch) => {
    const body = JSON.stringify({ username, password, companyCode });
    dispatch({
        type: LOADING_AUTH,
    });

    try {
        const res = await axios.post(`${api}access`, body, { headers });

        const [perfil] = res.data.user.roles;

        if (loginAccess[perfil]) {
            setAuthToken(res.data.access_token);
            dispatch({
                type: LOGIN_SUCCESS,
                payload: res.data,
            });
            saveToken(res.data);
        } else {
            dispatch(setAlert(i18n.t("alert:wrong_credentials"), "error"));
            dispatch({
                type: LOGIN_FAIL,
            });
            removeToken(dispatch);
        }

        _getCompany(dispatch);
    } catch (err) {
        dispatch(setAlert(i18n.t("alert:wrong_credentials"), "error"));
        dispatch({
            type: LOGIN_FAIL,
        });
        removeToken(dispatch);
    }
};

export const validRegister = (name, lastName, legalId, email, password) => async (dispatch) => {
    const body = JSON.stringify({ email, apiKey });
    dispatch({
        type: LOADING_AUTH,
    });
    
    try {
        const res = await axios.post(`${api}validate/request`, body, { headers: { Company: companyCode, ...headers } });
        dispatch({
            type: REGISTER_VALIDATE,
            payload: {
                token: res.data.token, 
                registerData: { name, lastName, legalId, email, password } 
            }
        });
        dispatch(setAlert(i18n.t(`alert:check_email`), "success"));
    } catch (err) {
        if (err.response && err.response.status === 412) {
            dispatch(setAlert(i18n.t(`alert:email_already_exists`), "error"))
        }
        else {
            dispatch(setAlert(i18n.t(`alert:error`), "error"))
        }
        dispatch({
            type: REGISTER_FAIL,
        });
    }
};

export const register = (token, code, registerData) => async (dispatch) => {
    const body = JSON.stringify({ code, token, apiKey });
    dispatch({
        type: LOADING_AUTH,
    });
    
    try {
        await axios.post(`${api}validate`, body, { headers: { Company: companyCode, ...headers } });
        const bodyReg = JSON.stringify(registerData);
        try {
            const res = await axios.post(`${api}registrations`, bodyReg, { headers: { Company: companyCode, ...headers } });
            setAuthToken(res.data.access_token);
            dispatch({
                type: REGISTER_SUCCESS,
                payload: res.data,
            });
            saveToken(res.data);
        } catch (error) {
            dispatch(setAlert(i18n.t(`alert:error`), "error"));
            dispatch({
                type: REGISTER_FAIL,
            });
            removeToken(dispatch);
        }
    } catch (err) {
        dispatch(setAlert(i18n.t(`alert:error_reg_code`), "error"));
        dispatch({
            type: REGISTER_FAIL,
        });
    }
};

export const logout = () => (dispatch) => {
    setAuthToken();
    dispatch({
        type: LOGOUT,
    });
    removeToken(dispatch);
};
