const domainSic = `${process.env.REACT_APP_URL}/api/`;

export const companies = {
    lamundial: {
        production:{
            api: domainSic,
            cdnFiles: (filename) => `${domainSic}v1/uploads/${filename}`,
            companyCode: "la-tienda-mundial",
            docTitle: "LaMundial"
        },
        development:{
            api: domainSic,
            cdnFiles: (filename) => `${domainSic}v1/uploads/${filename}`,
            companyCode: "la-tienda-mundial",
            docTitle: "LaMundial"
        },
    },
    agrosano: {
        production:{
            api: domainSic,
            cdnFiles: (filename) => `${domainSic}v1/uploads/${filename}`,
            companyCode: "demo-ecomm",
            docTitle: "Agrosano"
        },
        development:{
            api: domainSic,
            cdnFiles: (filename) => `${domainSic}v1/uploads/${filename}`,
            companyCode: "agrosano",
            docTitle: "Agrosano"
        }
    },
    tumercadocordoba: {
        production:{
            api: domainSic,
            cdnFiles: (filename) => `${domainSic}v1/uploads/${filename}`,
            companyCode: "tumercado",
            docTitle: "Tu mercado Córdoba"
        },
        development:{
            api: domainSic,
            cdnFiles: (filename) => `${domainSic}v1/uploads/${filename}`,
            companyCode: "tumercado",
            docTitle: "Tu mercado Córdoba"
        }
    }
};

export default function (company){
    const conf = companies[company];
    const enviroment = process.env.NODE_ENV;
    return conf[enviroment];
} 