import React, { memo } from "react";
import Loader from "react-loader-spinner";
import "./loading.scss";

const Loading = () => (
    <div className="loadingWrapper">
        <Loader type="ThreeDots" className="loader" />
    </div>
);

export default memo(Loading);
