import {
    GET_USERS_REQUEST,
    GET_USERS_SUCCESS,
    GET_USERS_FAILURE,
    GET_TYPING_USERS_REQUEST,
    SET_USERS,
    SET_USER,
    SUBMIT_USER_SUCCESS,
    SUBMIT_USER_REQUEST,
    SUBMIT_USER_FAILURE
} from '../constants/actions/types';
import { buildUsers } from '../helpers/users';
const initialState = {
    fetching: true,
    typingFetching: false,
    submitFetching: false,
    submitSuccess: false,
    users: [],
    user: {},
    total: 0
};

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_USERS_REQUEST: {
            return {
                ...state,
                fetching: true,
                submitSuccess: false
            };
        }
        case GET_TYPING_USERS_REQUEST: {
            return {
                ...state,
                typingFetching: true,
                users: [],
                total: 0
            };
        }
        case GET_USERS_SUCCESS: {
            return {
                ...state,
                fetching: false,
                typingFetching: false,
                users: state.users.concat(buildUsers(action.payload.users, action.payload.profiles)),
                total: (!action.payload.total || action.payload.total === 0) ? state.total : action.payload.total
            };
        }
        case GET_USERS_FAILURE: {
            return {
                ...state,
                fetching: false
            };
        }
        case SET_USERS: {
            return {
                ...state,
                users: action.payload.users,
                total: action.payload.total
            };
        }
        case SET_USER: {
            return {
                ...state,
                user: action.payload.user
            };
        }

        case SUBMIT_USER_REQUEST: {
            return {
                ...state,
                submitFetching: true,
                submitSuccess: false
            };
        }
        case SUBMIT_USER_SUCCESS: {
            return {
                ...state,
                submitFetching: false,
                submitSuccess: true,
                users:[]
            };
        }
        case SUBMIT_USER_FAILURE: {
            return {
                ...state,
                submitFetching: false,
                submitSuccess: false
            };
        }

        default:
            return state;
    }
};
