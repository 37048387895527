import { memo, useEffect } from 'react';
import { useSelector } from "react-redux";
import { company, docTitle } from '../../constants/utils/conf';

const ChangeTheme = () => {
    const theme = useSelector((state) => state.config.theme);
    const icon = useSelector( state => state.company.picture );
    useEffect(() => {
        document.body.setAttribute('data-theme', `${company}-${theme}`);

        let faviconTag = document.getElementById('favicon');
        faviconTag.href = icon ? icon :`${process.env.PUBLIC_URL}/assets/${company}/logos/favicon.ico`;
        document.title = docTitle;

    }, [theme, icon]);
    return null;
};

export default memo(ChangeTheme);
