import { SET_FILTERS_PURCHASES } from "../constants/actions/types";
import moment from "moment";

export const initialState = {
    dateFrom: new Date(moment().set({ hours: 0, minutes: 0, seconds: 0 }).subtract(2, "month")),
    dateTo: new Date(moment().set({ hours: 23, minutes: 59, seconds: 59 })),
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_FILTERS_PURCHASES: {
            return {
                ...state,
                dateFrom: action.payload.dateFrom,
                dateTo: action.payload.dateTo,
            };
        }
        default:
            return state;
    }
};
