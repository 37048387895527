import axios from 'axios';
import {api, headers} from '../constants/utils/conf';
import { 
    GET_COMPANY_REQUEST, 
    GET_COMPANY_FAILURE, 
    GET_COMPANY_SUCCESS, 
    PUT_COMPANY_FAILURE, 
    PUT_COMPANY_REQUEST, 
    PUT_COMPANY_SUCCESS,
    GET_DELIVERY_METHODS_REQUEST,
    GET_DELIVERY_METHODS_SUCCESS,
    GET_DELIVERY_METHODS_FAILURE,
    GET_PAYMENT_METHODS_REQUEST,
    GET_PAYMENT_METHODS_SUCCESS,
    GET_PAYMENT_METHODS_FAILURE 
} from "../constants/actions/types";
import { checkFileTypeAndUpload } from '../helpers/uploadFiles';
import { setAlert } from './alert';
import i18n from 'i18next';
import setAuthorization from "../constants/utils/setAuthorization";

export const getcompanyRequest = ()=>({
    type: GET_COMPANY_REQUEST
})

export const getcompanyFailure  = ()=>({
    type: GET_COMPANY_FAILURE
})

export const getcompanySuccess = (company)=>({
    type: GET_COMPANY_SUCCESS,
    payload: company
})
export const putcompanySuccess = (company)=>({
    type: PUT_COMPANY_SUCCESS,
    payload: company
})
export const putcompanyRequest = ()=>({
    type: PUT_COMPANY_REQUEST
})
export const putcompanyFailure = ()=>({
    type: PUT_COMPANY_FAILURE
})

export const getCompany = (filters)=> dispatch => _getCompany(dispatch,filters);
export const putCompany = (company) => dispatch => _putCompany(dispatch,company);

export const _getCompany = async ( dispatch )=>{
    const authHeaders = { ...setAuthorization(), ...headers };

    dispatch(getcompanyRequest());
    try{
        const {data:{company}} = await axios.get(`${api}v1/companies`, { headers: authHeaders });
        dispatch(getcompanySuccess(company));
    }
    catch(err){
        console.error(err);
        dispatch(getcompanyFailure(err));
    }
}

export const _putCompany = async ( dispatch , company ) => {
    dispatch(putcompanyRequest());
    try{
        let allFileNames = ''; 
        if(company.picture){
            allFileNames = await checkFileTypeAndUpload(company.picture[0]);
            
        }
        
        company.picture = company.picture ? allFileNames.filenames : "";

        const {data} = await axios.put(`${api}v1/companies`, company, {...headers});
        dispatch(putcompanySuccess(data.company));
        dispatch(setAlert(i18n.t(`alert:updated`), "success", true));
        
    }
    catch(err){
        console.error(err)
        dispatch(setAlert(i18n.t(`alert:error`), "error", true));
        dispatch(putcompanyFailure());
    }
}

export const getDeliveryMethods = () => async (dispatch) => {
    dispatch({
        type: GET_DELIVERY_METHODS_REQUEST
    });

    try {
        const res = await axios.get(`${api}v1/delivery-methods`, { headers });
        dispatch({
            type: GET_DELIVERY_METHODS_SUCCESS,
            payload: res.data,
        });
    } catch (err) {
        dispatch({
            type: GET_DELIVERY_METHODS_FAILURE,
            error: err,
        });
    }
};

export const getPaymentMethods = () => async (dispatch) => {
    dispatch({
        type: GET_PAYMENT_METHODS_REQUEST
    });

    try {
        const res = await axios.get(`${api}v1/payment-methods`, { headers });
        dispatch({
            type: GET_PAYMENT_METHODS_SUCCESS,
            payload: res.data,
        });
    } catch (err) {
        dispatch({
            type: GET_PAYMENT_METHODS_FAILURE,
            error: err,
        });
    }
};