import { parseToDni } from "./commons";

export const buildUsers = (users, profiles) => users
    .map(user => buildUser(user, profiles));

export const buildUser = (user, profiles) => ({
    ...user,
    company: Array.isArray(user.company) ? user.company[0] : user.company,
    profile: profiles
        ? buildUserProfile(user.roles, profiles)
        : Array.isArray(user.profile)
            ? user.profile[0]
            : user.roles,
    displayName: `${user.name} ${user.lastName}`,
    displayNameWithUsername: `${user.name} ${user.lastName} (${user.username})`,
    identifier: parseToDni(user.legalId),
});

const buildUserProfile = (user_profile, profiles) => {
    if (user_profile[0]) {

        if (typeof user_profile[0] === "string") {
            return profiles.find(p => p._id === user_profile[0])
        } else {
            return user_profile[0]
        }

    } else {
        return {}
    }
};
