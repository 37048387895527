import {
    GET_PRODUCTSTYPES_REQUEST,
    GET_PRODUCTSTYPES_SUCCESS,
    GET_PRODUCTSTYPES_FAILURE,
    SET_PRODUCTSTYPES,
    SET_PRODUCTSTYPE,
    SUBMIT_PRODUCTSTYPES_REQUEST,
    SUBMIT_PRODUCTSTYPES_SUCCESS,
    SUBMIT_PRODUCTSTYPES_FAILURE
} from '../constants/actions/types';

import { buildProductsTypesGet } from "../helpers/productsTypes";

const initialState = {
    request: {
        fetching: true,
        success: false
    },
    submit: {
        fetching: false,
        success: false
    },
    productsTypes: [],
    productsType: {},
    total: 0
};

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_PRODUCTSTYPES_REQUEST: {
            return {
                ...state,
                request: {
                    ...state.request,
                    fetching: true
                },
                submit: {
                    ...state.submit,
                    success: false
                },
            };
        }
        case GET_PRODUCTSTYPES_SUCCESS: {
            return {
                ...state,
                request: {
                    ...state.request,
                    fetching: false
                },
                productsTypes: [
                    ...state.productsTypes,
                    ...buildProductsTypesGet(action.payload.productTypes)
                ],
                total: (!action.payload.total || action.payload.total === 0)
                    ? state.total
                    : action.payload.total
            };
        }
        case GET_PRODUCTSTYPES_FAILURE: {
            return {
                ...state,
                request: {
                    ...state.request,
                    fetching: false
                }
            };
        }
        case SET_PRODUCTSTYPES: {
            return {
                ...state,
                productsTypes: action.payload.productsTypes,
                total: action.payload.total
            };
        }
        case SET_PRODUCTSTYPE: {
            return {
                ...state,
                productsType: action.payload.productsTypes
            };
        }

        case SUBMIT_PRODUCTSTYPES_REQUEST: {
            return {
                ...state,
                submit: {
                    ...state.submit,
                    fetching: true
                }
            };
        }
        case SUBMIT_PRODUCTSTYPES_SUCCESS: {
            return {
                ...state,
                submit: {
                    ...state.submit,
                    fetching: false,
                    success: true
                },
                productsType:{},
                productsTypes: [],
            };
        }
        case SUBMIT_PRODUCTSTYPES_FAILURE: {
            return {
                ...state,
                submit: {
                    ...state.submit,
                    fetching: false,
                    success: false
                }
            };
        }
        default:{
            return state;
        }
    }
};
