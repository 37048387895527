import { company } from "../constants/utils/conf";

const defaultAssets = {
    angleLeftSVG :`/assets/icons/angle-line-left.svg`,
    angleRightSVG : `/assets/icons/angle-line-rigth.svg`,
    angleDownSVG : `/assets/icons/angle-line-down.svg`,
    angleUpSVG : `/assets/icons/angle-line-up.svg`,
    cancelSVG : `/assets/icons/close.svg`,
    logoutSVG : `/assets/icons/logout.svg`,
    plusSVG : `/assets/icons/plus.svg`,
    settingSVG : `/assets/icons/settings.svg`,
    productSVG : `/assets/icons/products.svg`,
    dashboardSVG : `/assets/icons/dashboard.svg`,
    productTypesSVG : `/assets/icons/productTypes.svg`,
    providersSVG : `/assets/icons/providers.svg`,
    purchasesSVG : `/assets/icons/purchases.svg`,
    salesSVG : `/assets/icons/sales.svg`,
    userSVG : `/assets/icons/user.svg`,
    whatsappIcon : `/assets/icons/whatsappIcon.png`,
    webConfigurationSVG : `/assets/icons/format-color-fill.svg`,
    cameraSVG : `/assets/icons/camera.svg`,
    warningsBackgroudSVG : `/assets/images/warning.svg`,
    companyBackgroudSVG : `/assets/images/defaultProduct.png`,
    famaleSVG : `/assets/images/female.svg`,
    maleSVG : `/assets/images/male.svg`,
    peopleSVG : `/assets/images/people.svg`,
    downloadSVG : `/assets/icons/file-download.svg`,
    xslxIcon : `/assets/icons/xlsx-file.svg`,
    xslxLogo : `/assets/icons/xlsx-logo.svg`,
    plus1SVG : `/assets/icons/plus1.svg`,
    deleteFillSVG : `/assets/icons/trash-fill-icon.svg`,
    trashOutlineIcon : `/assets/icons/trash-outline-icon.svg`,
    companySVG: `/assets/icons/company.svg`,
    searchSVG: `/assets/icons/search.svg`,
    userWhiteSVG: `/assets/icons/user-white.svg`,
    cartSVG: `/assets/icons/cart.svg`,
    facebookSVG: `/assets/icons/facebook.svg`,
    instagramSVG: `/assets/icons/instagram.svg`,
    promotionsSVG: `/assets/icons/promotions.svg`,
    paymentMethodsSVG: `/assets/icons/payment-methods.svg`,
    deliverySVG: `/assets/icons/delivery.svg`,
    deliveryTruckSVG: `/assets/icons/delivery-truck.svg`,
    fiscalImg: `/assets/images/fiscal.jpg`,
    checkCircleSVG: `/assets/icons/check-circle-line.svg`,
    towSVG: `/assets/icons/veggie.svg`,
    medal1SVG: `/assets/icons/medal1.svg`,
    medal2SVG: `/assets/icons/medal2.svg`,
    medal3SVG: `/assets/icons/medal3.svg`,
    crosshairsSVG: `/assets/icons/crosshairs-gps.svg`,
    branchesImg: `./assets/markers/branches.png`,
    androidSVG : `/assets/icons/android.svg`,
};

const images = {
    agrosano:{
        logotipoSVG : `/assets/${company}/logos/logotipo.svg`,
        logotipoLoginSVG : `/assets/${company}/logos/logotipo.svg`,
        logotipoLoginSVG2 : `/assets/${company}/logos/logotipo2.svg`,
        ...defaultAssets,
    },
    lamundial:{
        logotipoSVG : `/assets/${company}/logos/logotipo.png`,
        logotipoLoginSVG : `/assets/${company}/logos/logotipo.png`,
        logotipoLoginSVG2 : `/assets/${company}/logos/logotipo2.png`,
        ...defaultAssets,
    },
    tumercadocordoba:{
        logotipoSVG : `/assets/${company}/logos/logotipo.png`,
        logotipoLoginSVG : `/assets/${company}/logos/logotipo.png`,
        logotipoLoginSVG2 : `/assets/${company}/logos/logotipo2.png`,
        ...defaultAssets,
    }
}

export const {
    logotipoSVG,
    angleRightSVG,
    angleLeftSVG,
    angleDownSVG,
    angleUpSVG,
    cancelSVG,
    logoutSVG,
    whatsappIcon,
    plusSVG,
    settingSVG,
    productSVG,
    dashboardSVG,
    productTypesSVG,
    providersSVG,
    purchasesSVG,
    salesSVG,
    userSVG,
    webConfigurationSVG,
    cameraSVG,
    warningsBackgroudSVG,
    companyBackgroudSVG,
    famaleSVG,
    maleSVG,
    peopleSVG,
    logotipoLoginSVG,
    logotipoLoginSVG2,
    downloadSVG,
    xslxIcon,
    plus1SVG,
    xslxLogo,
    deleteFillSVG,
    trashOutlineIcon,
    companySVG,
    searchSVG,
    userWhiteSVG,
    cartSVG,
    facebookSVG,
    instagramSVG,
    promotionsSVG,
    paymentMethodsSVG,
    deliverySVG,
    deliveryTruckSVG,
    fiscalImg,
    checkCircleSVG,
    towSVG,
    medal1SVG,
    medal2SVG,
    medal3SVG,
    crosshairsSVG,
    branchesImg,
    androidSVG
} = images[company]