import { cdnFiles } from "../constants/utils/conf";
import moment from "moment";

export const buildProductsGet = (products) => products.map((product) => buildProductGet(product));

export const buildProductGet = (product) => {
    const access_token = localStorage.getItem("access_token");
    const pictureUrl = product.picture ? `${cdnFiles(product.picture)}?token=${access_token}` : null;
    const thumbnailUrl = product.thumbnail ? `${cdnFiles(product.thumbnail)}?token=${access_token}` : null;
    return {
        ...product,
        pictureUrl,
        thumbnailUrl,
    };
};

export const buildProductPost = (data) => {
    let _data = Object.assign({}, data);

    if (data.products) {
        data.products.map((item, index) => {
            if (!_data.products[index]._id) {
                return _data.products[index].product = item.product.value;
            } 
            return _data;
        });
    }

    if (data.discount) {
        _data.discount = {
            percentage: data.discount,
            validity: {
                from: data.dateFrom,
                to: data.dateTo,
            },
        };
    }
    delete _data.dateFrom;
    delete _data.dateTo;

    if (data.providers && data.providers.value) {
        _data.providers = [data.providers.value];
    } else {
        _data.providers = [];
    }

    if (data.productType && data.productType.value) {
        _data.productType = data.productType.value;
    } else {
        _data.productType = "";
    }

    _data.tax = {
        percentage: data.tax,
    };

    return _data;
};

export const buildProductForm = (data) => {
    let _data = Object.assign({}, data);

    if (data.products) {
        data.products.map((item, index) => {
            return (_data.products[index].product.label = item.product.name);
        });
    }

    if (data.productType) {
        _data.productType = { label: data.productType.name, value: data.productType._id };
    }

    if (data.providers[0]) {
        _data.providers = { label: data.providers[0].name, value: data.providers[0]._id };
    }

    if (data.discount) {
        if (data.discount.percentage) {
            _data.discount = data.discount.percentage;
        }

        if (data.discount.validity.from && data.discount.validity.to) {
            _data.dateFrom = data.discount.validity.from;
            _data.dateTo = data.discount.validity.to;
        }
    } else {
        _data.discount = "";
        _data.dateFrom = new Date(moment().set({ hours: 0, minutes: 0, seconds: 0 }));
        _data.dateTo = new Date(moment().set({ hours: 23, minutes: 59, seconds: 59 }).add(1, "month"));
    }

    if (data.tax && data.tax.percentage) {
        _data.tax = data.tax.percentage;
    } else {
        _data.tax = "";
    }

    if (data.picture) {
        _data.picture = data.pictureUrl;
        _data.thumbnail = data.thumbnailUrl;
    }
    delete _data.pictureUrl;
    delete _data.thumbnailUrl;

    return _data;
};

export const nanToZero = (value)=>{
    return value ? value : 0;
}

export const getFinalPrice = (product, withDiscount) => {
    const { price, tax, discount } = product;
    const finalDiscount = discount ? (price * discount.percentage) / 100 : 0;
    const finalTax = tax ? (price * tax.percentage) / 100 : 0;

    let result;
    withDiscount 
        ? result = price - finalDiscount + finalTax 
        : result = price + finalTax;

    return result.toFixed(2);
};