const defaultPermission = {
    products:false,
    purchases:false,
    sales:false,
    dashboard:false,
    providers:false,
    producttypes:false,
    clients:false,
    settings:{
        users:false,
        web_configuration:false,
        business:false,
    },
}

const defaultPermissionRender = {
    products:false,
    purchases:false,
    sales:false,
    dashboard:false,
    providers:false,
    producttypes:false,
    users:false,
    web_configuration:false,
    business:false
}

export const loginAccess = {
    administrator:true,
    operator:true,
    user:true
}

export const menuPermission = {
    administrator:{
        ...defaultPermission,
        products:true,
        purchases:true,
        sales:true,
        dashboard:true,
        providers:true,
        producttypes:true,
        clients:true,
        settings:{
            users:true,
            web_configuration:true,
            business:true
        },
    },
    operator:{
        ...defaultPermission,
        products:true,
        purchases:true,
        sales:true,
        dashboard:true,
        providers:true,
        producttypes:true,
        clients:true,
        settings:{
            users:false,
            web_configuration:true,
            business:false
        },
    },
    user:{
        ...defaultPermission,
        products:false,
        purchases:false,
        sales:false,
        dashboard:false,
        providers:false,
        producttypes:false,
        clients:false,
        settings:{
            users:false,
            web_configuration:false,
            business:false
        },
    }
}

export const componentsPermission = {
    administrator:{
        ...defaultPermissionRender,
        products:true,
        purchases:true,
        sales:true,
        dashboard:true,
        providers:true,
        producttypes:true,
        users:true,
        web_configuration:true,
        business:true,
        clients:true
    },
    operator:{
        ...defaultPermissionRender,
        products:true,
        purchases:true,
        sales:true,
        dashboard:true,
        providers:true,
        producttypes:true,
        users:false,
        web_configuration:true,
        business:false,
        clients:true
    },
    user:{
        ...defaultPermissionRender,
        products:false,
        purchases:false,
        sales:false,
        dashboard:false,
        providers:false,
        producttypes:false,
        users:false,
        web_configuration:false,
        business:false,
        clients:false
    }
}