import {
    GET_STATISTICS_PERIOD_REQUEST,
    GET_STATISTICS_PERIOD_SUCCESS,
    GET_STATISTICS_PERIOD_FAILURE,
    GET_STATISTICS_HOURS_REQUEST,
    GET_STATISTICS_HOURS_SUCCESS,
    GET_STATISTICS_HOURS_FAILURE,
    GET_STATISTICS_PRODUCTS_REQUEST,
    GET_STATISTICS_PRODUCTS_SUCCESS,
    GET_STATISTICS_PRODUCTS_FAILURE,
    GET_STATISTICS_PRODUCT_TYPES_REQUEST,
    GET_STATISTICS_PRODUCT_TYPES_SUCCESS,
    GET_STATISTICS_PRODUCT_TYPES_FAILURE
} from '../constants/actions/types';

const initialState = {
    period: {
        fetching: true,
        statistics: [],
    },
    hours: {
        fetching: true,
        statistics: [],
    },
    products: {
        fetching: true,
        statistics: [],
    },
    productTypes: {
        fetching: true,
        statistics: [],
    },
    total: 0
};

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_STATISTICS_PERIOD_REQUEST: {
            return {
                ...state,
                period: {
                    ...state.period,
                    fetching: true,
                },
            };
        }
        case GET_STATISTICS_PERIOD_SUCCESS: {
            return {
                ...state,
                period: {
                    fetching: false,
                    statistics: action.payload.soldProducts
                },
            };
        }
        case GET_STATISTICS_PERIOD_FAILURE: {
            return {
                ...state,
                period: {
                    fetching: false,
                },
            };
        }
        case GET_STATISTICS_HOURS_REQUEST: {
            return {
                ...state,
                hours: {
                    ...state.hours,
                    fetching: true,
                },
            };
        }
        case GET_STATISTICS_HOURS_SUCCESS: {
            return {
                ...state,
                hours: {
                    fetching: false,
                    statistics: action.payload.hours
                },
            };
        }
        case GET_STATISTICS_HOURS_FAILURE: {
            return {
                ...state,
                hours: {
                    fetching: false,
                },
            };
        }
        case GET_STATISTICS_PRODUCTS_REQUEST: {
            return {
                ...state,
                products: {
                    ...state.products,
                    fetching: true,
                },
            };
        }
        case GET_STATISTICS_PRODUCTS_SUCCESS: {
            return {
                ...state,
                products: {
                    fetching: false,
                    statistics: action.payload.products
                },
            };
        }
        case GET_STATISTICS_PRODUCTS_FAILURE: {
            return {
                ...state,
                products: {
                    fetching: false,
                },
            };
        }
        case GET_STATISTICS_PRODUCT_TYPES_REQUEST: {
            return {
                ...state,
                productTypes: {
                    ...state.productTypes,
                    fetching: true,
                },
            };
        }
        case GET_STATISTICS_PRODUCT_TYPES_SUCCESS: {
            return {
                ...state,
                productTypes: {
                    fetching: false,
                    statistics: action.payload.familyProducts
                },
            };
        }
        case GET_STATISTICS_PRODUCT_TYPES_FAILURE: {
            return {
                ...state,
                productTypes: {
                    fetching: false,
                },
            };
        }
        default:
            return state;
    }
};
