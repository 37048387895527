import {
    LOADING_AUTH,
    REFRESH_SUCCESS,
    REFRESH_FAIL,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    REGISTER_SUCCESS,
    REGISTER_VALIDATE,
    REGISTER_FAIL,
    LOGOUT,
    USER_AUTH,
    NO_REFRESH,
} from "../constants/actions/types";

const initialState = {
    isAuthenticated: false,
    loading: true,
    user: null,
    token: null,
    registerData: {}
};

export default function (state = initialState, action) {
    const { type, payload } = action;
    
    switch (type) {
        case LOADING_AUTH:
            return {
                ...state,
                loading: true,
            };
        case NO_REFRESH:
            return {
                ...state,
                isAuthenticated: true,
                loading: false,
            };
        case LOGIN_SUCCESS:
        case REGISTER_SUCCESS:
            return {
                ...state,
                isAuthenticated: true,
                loading: false,
                user: payload.user,
                token: null
            };
        case REFRESH_SUCCESS:
            return {
                ...state,
                isAuthenticated: true,
                loading: false,
            };
        case REGISTER_VALIDATE:
            return {
                ...state,
                token: payload.token,
                registerData: payload.registerData,
                loading: false,
            }
        case REGISTER_FAIL:
            return {
                ...state,
                loading: false,
                token: null,
            };
        case LOGIN_FAIL:
        case REFRESH_FAIL:
        case LOGOUT:
            return {
                ...state,
                isAuthenticated: false,
                loading: false,
                user: null,
            };
        case USER_AUTH:
            return {
                ...state,
                user: payload.user,
            };
        default:
            return state;
    }
}
