export const LOADING_AUTH = "LOADING_AUTH";
export const ADD_COUNTER = "ADD_COUNTER";
export const RESET_COUNTER = "RESET_COUNTER";
export const REFRESH_SUCCESS = "REFRESH_SUCCESS";
export const REFRESH_FAIL = "REFRESH_FAIL";
export const NO_REFRESH = "NO_REFRESH";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_VALIDATE = "REGISTER_VALIDATE";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const LOGOUT = "LOGOUT";
export const USER_AUTH = "USER_AUTH";
export const SET_ALERT = "SET_ALERT";

export const GET_USERS_REQUEST = "GET_USERS_REQUEST";
export const GET_TYPING_USERS_REQUEST = "GET_TYPING_USERS_REQUEST";
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS";
export const GET_USERS_FAILURE = "GET_USERS_FAILURE";
export const SET_USERS = "SET_USERS";
export const SET_USER = "SET_USER";
export const SUBMIT_USER_REQUEST = "SUBMIT_USER_REQUEST";
export const SUBMIT_USER_SUCCESS = "SUBMIT_USER_SUCCESS";
export const SUBMIT_USER_FAILURE = "SUBMIT_USER_FAILURE";
export const SET_FILTERS_USERS = "SET_FILTERS_USERS";

export const SET_THEME = "SET_THEME";
export const SET_VERSION = "SET_VERSION";

export const GET_PRODUCTS_REQUEST = "GET_PRODUCTS_REQUEST";
export const GET_PRODUCTS_SUCCESS = "GET_PRODUCTS_SUCCESS";
export const GET_PRODUCTS_FAILURE = "GET_PRODUCTS_FAILURE";
export const GET_PRODUCT_REQUEST = "GET_PRODUCT_REQUEST";
export const GET_PRODUCT_SUCCESS = "GET_PRODUCT_SUCCESS";
export const GET_PRODUCT_FAILURE = "GET_PRODUCT_FAILURE";
export const SET_PRODUCTS = "SET_PRODUCTS";
export const SET_PRODUCT = "SET_PRODUCT";
export const SUBMIT_PRODUCT_REQUEST = "SUBMIT_PRODUCT_REQUEST";
export const SUBMIT_PRODUCT_SUCCESS = "SUBMIT_PRODUCT_SUCCESS";
export const SUBMIT_PRODUCT_FAILURE = "SUBMIT_PRODUCT_FAILURE";
export const SET_FILTERS_PRODUCTS = "SET_FILTERS_PRODUCTS";
export const GET_PRODUCT_FILE_EXAMPLE_REQUEST = "GET_PRODUCT_FILE_EXAMPLE_REQUEST";
export const GET_PRODUCT_FILE_EXAMPLE_SUCCESS = "GET_PRODUCT_FILE_EXAMPLE_SUCCESS";
export const GET_PRODUCT_FILE_EXAMPLE_FAILURE = "GET_PRODUCT_FILE_EXAMPLE_FAILURE";
export const UPLOAD_PRODUCT_FILE_EXAMPLE_REQUEST = "UPLOAD_PRODUCT_FILE_EXAMPLE_REQUEST";
export const UPLOAD_PRODUCT_FILE_EXAMPLE_SUCCESS = "UPLOAD_PRODUCT_FILE_EXAMPLE_SUCCESS";
export const UPLOAD_PRODUCT_FILE_EXAMPLE_FAILURE = "UPLOAD_PRODUCT_FILE_EXAMPLE_FAILURE";

export const DELETE_PRODUCT_REQUEST = "DELETE_PRODUCT_REQUEST";
export const DELETE_PRODUCT_SUCCESS = "DELETE_PRODUCT_SUCCESS";
export const DELETE_PRODUCT_FAILURE = "DELETE_PRODUCT_FAILURE";
export const CHANGE_STATUS_PRODUCT_REQUEST = "CHANGE_STATUS_PRODUCT_REQUEST";
export const CHANGE_STATUS_PRODUCT_SUCCESS = "CHANGE_STATUS_PRODUCT_SUCCESS";
export const CHANGE_STATUS_PRODUCT_FAILURE = "CHANGE_STATUS_PRODUCT_FAILURE";

export const GET_PROVIDERS_ON_FILTERS_REQUEST = "GET_PROVIDERS_ON_FILTERS_REQUEST";
export const GET_PROVIDERS_ON_FILTERS_SUCCESS = "GET_PROVIDERS_ON_FILTERS_SUCCESS";
export const GET_PROVIDERS_ON_FILTERS_FAILURE = "GET_PROVIDERS_ON_FILTERS_FAILURE";
export const CLEAR_PROVIDERS_ON_FILTERS = "CLEAR_PROVIDERS_ON_FILTERS";

export const GET_PRODUCT_TYPES_ON_FILTERS_REQUEST = "GET_PRODUCT_TYPES_ON_FILTERS_REQUEST";
export const GET_PRODUCT_TYPES_ON_FILTERS_SUCCESS = "GET_PRODUCT_TYPES_ON_FILTERS_SUCCESS";
export const GET_PRODUCT_TYPES_ON_FILTERS_FAILURE = "GET_PRODUCT_TYPES_ON_FILTERS_FAILURE";
export const CLEAR_PRODUCT_TYPES_ON_FILTERS = "CLEAR_PRODUCT_TYPES_ON_FILTERS";

export const GET_PRODUCTS_ON_FILTERS_REQUEST = "GET_PRODUCTS_ON_FILTERS_REQUEST";
export const GET_PRODUCTS_ON_FILTERS_SUCCESS = "GET_PRODUCTS_ON_FILTERS_SUCCESS";
export const GET_PRODUCTS_ON_FILTERS_FAILURE = "GET_PRODUCTS_ON_FILTERS_FAILURE";
export const CLEAR_PRODUCTS_ON_FILTERS = "CLEAR_PRODUCTS_ON_FILTERS";

export const GET_PRODUCTSTYPES_REQUEST = "GET_PRODUCTSTYPES_REQUEST";
export const GET_PRODUCTSTYPES_SUCCESS = "GET_PRODUCTSTYPES_SUCCESS";
export const GET_PRODUCTSTYPES_FAILURE = "GET_PRODUCTSTYPES_FAILURE";
export const SET_PRODUCTSTYPES = "SET_PRODUCTSTYPES";
export const SET_PRODUCTSTYPE = "SET_PRODUCTSTYPE";
export const SUBMIT_PRODUCTSTYPES_REQUEST = "SUBMIT_PRODUCTSTYPES_REQUEST";
export const SUBMIT_PRODUCTSTYPES_SUCCESS = "SUBMIT_PRODUCTSTYPES_SUCCESS";
export const SUBMIT_PRODUCTSTYPES_FAILURE = "SUBMIT_PRODUCTSTYPES_FAILURE";
export const SET_FILTERS_PRODUCTSTYPES = "SET_FILTERS_PRODUCTSTYPES";

export const GET_PROVIDERS_REQUEST = "GET_PROVIDERS_REQUEST";
export const GET_PROVIDERS_SUCCESS = "GET_PROVIDERS_SUCCESS";
export const GET_PROVIDERS_FAILURE = "GET_PROVIDERS_FAILURE";
export const SET_PROVIDERS = "SET_PROVIDERS";
export const SET_PROVIDER = "SET_PROVIDER";
export const SUBMIT_PROVIDER_REQUEST = "SUBMIT_PROVIDER_REQUEST";
export const SUBMIT_PROVIDER_SUCCESS = "SUBMIT_PROVIDER_SUCCESS";
export const SUBMIT_PROVIDER_FAILURE = "SUBMIT_PROVIDER_FAILURE";
export const SET_FILTERS_PROVIDERS = "SET_FILTERS_PROVIDERS";

export const GET_SALES_REQUEST = "GET_SALES_REQUEST";
export const GET_SALES_SUCCESS = "GET_SALES_SUCCESS";
export const GET_SALES_FAILURE = "GET_SALES_FAILURE";
export const GET_SALE_SUCCESS = "GET_SALE_SUCCESS";
export const SET_SALES = "SET_SALES";
export const SET_SALE = "SET_SALE";
export const SET_FILTERS_SALES = "SET_FILTERS_SALES";
export const [PUT_SALE_REQUEST,PUT_SALE_SUCCESS,PUT_SALE_FAILURE] = ["PUT_SALE_REQUEST","PUT_SALE_SUCCESS","PUT_SALE_FAILURE"]
export const POST_SALE_REQUEST = "POST_SALE_REQUEST";
export const POST_SALE_SUCCESS = "POST_SALE_SUCCESS";
export const POST_SALE_FAILURE = "POST_SALE_FAILURE";
export const RESET_SALE_REQUEST = "RESET_SALE_REQUEST";
export const POST_MERCADOPAGO_REQUEST = "POST_MERCADOPAGO_REQUEST";
export const POST_MERCADOPAGO_SUCCESS = "POST_MERCADOPAGO_SUCCESS";
export const POST_MERCADOPAGO_FAILURE = "POST_MERCADOPAGO_FAILURE";
export const RESET_MERCADOPAGO_REQUEST = "RESET_MERCADOPAGO_REQUEST";
export const RESET_MERCADOPAGO_PREFERENCEID = "RESET_MERCADOPAGO_PREFERENCEID";


export const GET_PURCHASES_REQUEST = "GET_PURCHASES_REQUEST";
export const GET_PURCHASES_SUCCESS = "GET_PURCHASES_SUCCESS";
export const GET_PURCHASES_FAILURE = "GET_PURCHASES_FAILURE";
export const SET_PURCHASES = "SET_PURCHASES";
export const SET_PURCHASE = "SET_PURCHASE";
export const SUBMIT_PURCHASE_REQUEST = "SUBMIT_PURCHASE_REQUEST";
export const SUBMIT_PURCHASE_SUCCESS = "SUBMIT_PURCHASE_SUCCESS";
export const SUBMIT_PURCHASE_FAILURE = "SUBMIT_PURCHASE_FAILURE";
export const SET_FILTERS_PURCHASES = "SET_FILTERS_PURCHASES";

export const GET_COMPANY_REQUEST = "GET_COMPANY_REQUEST";
export const GET_COMPANY_FAILURE = "GET_COMPANY_FAILURE"; 
export const GET_COMPANY_SUCCESS = "GET_COMPANY_SUCCESS";
export const PUT_COMPANY_REQUEST = "PUT_COMPANY_REQUEST";
export const PUT_COMPANY_FAILURE = "PUT_COMPANY_FAILURE";
export const PUT_COMPANY_SUCCESS = "PUT_COMPANY_SUCCESS";
export const GET_DELIVERY_METHODS_REQUEST = "GET_DELIVERY_METHODS_REQUEST";
export const GET_DELIVERY_METHODS_SUCCESS = "GET_DELIVERY_METHODS_SUCCESS";
export const GET_DELIVERY_METHODS_FAILURE = "GET_DELIVERY_METHODS_FAILURE";
export const GET_PAYMENT_METHODS_REQUEST = "GET_PAYMENT_METHODS_REQUEST";
export const GET_PAYMENT_METHODS_SUCCESS = "GET_PAYMENT_METHODS_SUCCESS";
export const GET_PAYMENT_METHODS_FAILURE = "GET_PAYMENT_METHODS_FAILURE";

export const EXPORT_FILE_REQUEST = "EXPORT_FILE_REQUEST";
export const EXPORT_FILE_SUCCESS = "EXPORT_FILE_SUCCESS";
export const EXPORT_FILE_FAILURE = "EXPORT_FILE_FAILURE";

export const ADD_TO_CART = "ADD_TO_CART";
export const DELETE_FROM_CART = "DELETE_FROM_CART";
export const EMPTY_CART = "EMPTY_CART";
export const INCREASE_CART_PRODUCT = "INCREASE_CART_PRODUCT";
export const DECREASE_CART_PRODUCT = "DECREASE_CART_PRODUCT";
export const CHANGE_DELIVERY_TYPE = "CHANGE_DELIVERY_TYPE";
export const CHANGE_PAYMENT_METHOD = "CHANGE_PAYMENT_METHOD";

export const GET_STATISTICS_PERIOD_REQUEST = "GET_STATISTICS_PERIOD_REQUEST";
export const GET_STATISTICS_PERIOD_SUCCESS = "GET_STATISTICS_PERIOD_SUCCESS";
export const GET_STATISTICS_PERIOD_FAILURE = "GET_STATISTICS_PERIOD_FAILURE";
export const GET_STATISTICS_HOURS_REQUEST = "GET_STATISTICS_HOURS_REQUEST";
export const GET_STATISTICS_HOURS_SUCCESS = "GET_STATISTICS_HOURS_SUCCESS";
export const GET_STATISTICS_HOURS_FAILURE = "GET_STATISTICS_HOURS_FAILURE";
export const GET_STATISTICS_PRODUCTS_REQUEST = "GET_STATISTICS_PRODUCTS_REQUEST";
export const GET_STATISTICS_PRODUCTS_SUCCESS = "GET_STATISTICS_PRODUCTS_SUCCESS";
export const GET_STATISTICS_PRODUCTS_FAILURE = "GET_STATISTICS_PRODUCTS_FAILURE";
export const GET_STATISTICS_PRODUCT_TYPES_REQUEST = "GET_STATISTICS_PRODUCT_TYPES_REQUEST";
export const GET_STATISTICS_PRODUCT_TYPES_SUCCESS = "GET_STATISTICS_PRODUCT_TYPES_SUCCESS";
export const GET_STATISTICS_PRODUCT_TYPES_FAILURE = "GET_STATISTICS_PRODUCT_TYPES_FAILURE";




