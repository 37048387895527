import { lazy } from "react";
import {
    settingSVG,
    dashboardSVG,
    productSVG,
    userSVG,
    webConfigurationSVG,
    providersSVG,
    purchasesSVG,
    productTypesSVG,
    salesSVG,
    companySVG
} from "../../assets/index";

const Login = lazy(() => import("../../components/Login/Login"));
const Register = lazy(() => import("../../components/Register/Register"));
const Dashboard = lazy(() => import("../../components/Dashboard/Dashboard"));
const Products = lazy(() => import("../../components/Products/Products"));
const Purchases = lazy(() => import("../../components/Purchases/Purchases"));
const Sales = lazy(() => import("../../components/Sales/Sales"));
const Users = lazy(() => import("../../components/Users/Users"));

const Providers = lazy(() => import("../../components/Providers/Providers"));
const WebConfiguration = lazy(() => import("../../components/WebConfiguration/WebConfiguration"));
const ProductsTypes = lazy(() => import("../../components/ProductsTypes"));
const Business = lazy( ()=> import("../../components/Company/Company"));

const Home = lazy(() => import("../../components/Home/Home"));
const InternalProduct = lazy(() => import("../../components/InternalProduct/InternalProduct"));
const Cart = lazy(() => import("../../components/Cart/Cart"));
const User = lazy(() => import("../../components/User/User"));
const TermsAndConditions = lazy(() => import("../../components/TermsAndConditions/TermsAndConditions"));

export const adminRoutes = [
    {
        path: "/dashboard",
        component: Dashboard,
        icon: dashboardSVG,
        label: "dashboard",
        subtitle: "dashboard_subtitle",
    },
    {
        path: "/sales",
        component: Sales,
        icon: salesSVG,
        label: "sales",
        subtitle: "sales_subtitle",
    },
    {
        path: "/products",
        component: Products,
        icon: productSVG,
        label: "products",
        subtitle: "products_subtitle",
    },
    {
        path: "/purchases",
        component: Purchases,
        icon: purchasesSVG,
        label: "purchases",
        subtitle: "purchases_subtitle",
    },
    {
        path: "/clients",
        component: Users,
        icon: userSVG,
        label: "clients",
        subtitle: "users_subtitle",
    },
    {
        path: "/providers",
        component: Providers,
        icon: providersSVG,
        label: "providers",
        subtitle: "providers_subtitle",
    },
    {
        path: "/productTypes",
        component: ProductsTypes,
        icon: productTypesSVG,
        label: "producttypes",
        subtitle: "productstype_subtitle",
    },
    {
        disablePath: "/settings",
        icon: settingSVG,
        label: "settings",
        subtitle: "settings_subtitle",
        availableSubMenu: true,
    },
    {
        path: "/settings/users",
        component: Users,
        icon: userSVG,
        label: "users",
        subtitle: "users_subtitle",
    },
    {
        path: "/settings/webConfiguration",
        component: WebConfiguration,
        icon: webConfigurationSVG,
        label: "web_configuration",
        subtitle: "web_configuration_subtitle"
    },
    {
        path: "/settings/business",
        component: Business,
        icon: companySVG,
        label: "business",
        subtitle:"business_information"
    }
];

export const siteRoutes = [
    {
        path: "/signin",
        component: Login,
        label: "signin"
    },
    {
        path: "/signup",
        component: Register,
        label: "signup"
    },
    {
        path: "/home",
        component: Home,
        label: "home",
    },
    {
        path: "/home/:id",
        component: InternalProduct,
        label: "product",
    },
    {
        path: "/cart",
        component: Cart,
        label: "cart",
    },
    {
        path: "/user/general",
        component: User,
        label: "user",
        permissions: "siteAuth"
    },
    {
        path: "/user/data",
        component: User,
        label: "data",
        permissions: "siteAuth"
    },
    {
        path: "/user/addresses",
        component: User,
        label: "addresses",
        permissions: "siteAuth"
    },
    {
        path: "/user/purchases",
        component: User,
        label: "user_purchases",
        permissions: "siteAuth"
    },
    {
        path: "/user/purchases/:id",
        component: User,
        label: "user_purchases",
        permissions: "siteAuth"
    },
    {
        path: "/termsAndConditions",
        component: TermsAndConditions,
        label: "terms_and_conditions"
    }
]
