import {
    ADD_TO_CART,
    DELETE_FROM_CART,
    INCREASE_CART_PRODUCT,
    DECREASE_CART_PRODUCT,
    EMPTY_CART,
    CHANGE_DELIVERY_TYPE,
    CHANGE_PAYMENT_METHOD
} from "../constants/actions/types";
import { increaseProduct, decreaseProduct } from "../helpers/cart";

const initialState = {
    purchase: [],
    deliveryMethod: "",
    paymentMethod: ""
};

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case ADD_TO_CART:
            return {
                ...state,
                purchase: [...state.purchase, { product: payload.product, quantity: 1 }],
            };
        case DELETE_FROM_CART:
            return {
                ...state,
                purchase: [
                    ...state.purchase.filter((purch) => purch.product._id !== payload.product._id),
                ],
            };
        case INCREASE_CART_PRODUCT:
            return {
                ...state,
                purchase: [...increaseProduct(state.purchase, payload.product)],
            };
        case DECREASE_CART_PRODUCT:
            return {
                ...state,
                purchase: [...decreaseProduct(state.purchase, payload.product)],
            };
        case EMPTY_CART:
            return {
                ...state,
                purchase: [],
            };
        case CHANGE_DELIVERY_TYPE:
            return {
                ...state,
                deliveryMethod: payload.deliveryMethod
            };
        case CHANGE_PAYMENT_METHOD:
            return {
                ...state,
                paymentMethod: payload.paymentMethod
            };
        default:
            return state;
    }
}
